import React from 'react'
import logo from './logo.svg'
import twitter from './twitterBlue.svg'
import './App.css'

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
        <p className='headerText'>AKUDAMA</p>
      </header>
      <div>
        {' '}
        <p className='descriptionText'>
          Software development company, focusing on cryptocurrency solutions.
        </p>
        <a href='https://twitter.com/norbertbodziony'>
          <img src={twitter} className='twitter' alt='logo' />
        </a>
      </div>
      <div>
        <p className='footerText'>
          {' '}
          Akudama Sàrl, a company registered in the Swiss Confederation, number CHE-149.196.258.
          Registered office: Avenue des Boveresses 44, 1010 Lausanne - Switzerland.
        </p>
      </div>
    </div>
  )
}

export default App
